import { AuthGuard, RoleGuard } from './core/guards';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarContainerComponent } from './layout/components/navbar-container/navbar-container.component';

const routes: Routes = [
  {
    path: 'jobs',
    title: 'Jobs',
    data: {
      allowedRoles: ['SYSADMIN']
    },
    canActivate: [AuthGuard, RoleGuard],
    component: NavbarContainerComponent,
    loadChildren: () => import('./+jobs/jobs.module').then(m => m.JobsModule),
  },
  {
    path: 'tenants',
    title: 'Tenants',
    data: {
      allowedRoles: ['SYSADMIN']
    },
    canActivate: [AuthGuard, RoleGuard],
    component: NavbarContainerComponent,
    loadChildren: () => import('./+tenants/tenants.module').then(m => m.TenantsModule),
  },
  {
    path: 'services',
    title: 'Services',
    data: {
      allowedRoles: ['SYSADMIN']
    },
    canActivate: [AuthGuard, RoleGuard],
    component: NavbarContainerComponent,
    children:[
      {
        path: '',
        loadComponent: () => import('./pages/system/services/services.component').then(c=>c.ServicesComponent),
      },
    ]
  },
  {
    path: 'login',
    title: 'Login',
    loadChildren: () => import('./+login/login.module').then(m => m.LoginModule),
  },
  {
    path: '**',
    redirectTo: 'tenants',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
